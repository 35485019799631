import React, { useState } from 'react';
import Header from '@components/Header';
import Footer from '@components/Footer';
import Page from '@components/Page';
import { css } from '@emotion/react';
import { getPageElements } from '@lib/utils';
import { graphql } from 'gatsby';

export default function Flow({
  data: { page },
  pageContext: { language, refs, layouts, configuration }
}) {
  const { body } = getPageElements(page);

  return (
    <Page>
      <Header
        dark
        language={language}
        refs={refs}
        layouts={layouts}
      />
      <div
        className="py-5"
        css={css`
          h2,
          h3,
          h4 {
            margin-top: 1em;
          }
        `}
      >
        <div className="container">
          <h1 className="mb-4 text-center">{'Promise'}</h1>
          <div className="row">
            <div className="col-12 col-md-6 mb-3 mb-md-0 text-center">
              <a href={'/packages'} className={'btn btn-primary btn-lg shadow-sm'}>Ścieżka pakietowa</a>
            </div>
            <div className="col-12 col-md-6 text-center">
              <a href={'/questions'} className={'btn btn-primary btn-lg shadow-sm'}>Pytania</a>
            </div>
          </div>
        </div>

      </div>

      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Page>
  );
}

export const query = graphql`
  query($id: String) {
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
  }
`;